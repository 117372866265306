import React from 'react';
import './ShowExam.css';
import TabQuestions from './TabQuestions/TabQuestions';
import ShowQuestion from './ShowQuestion/ShowQuestion';
import { currentTabQuestionType, exam_chapterType } from '../../ChapterType';

type PropsType = exam_chapterType & { setExam_chapters: (exam_chapters: exam_chapterType[]) => void };

const ShowExam: React.FC<PropsType> = (props) => {
  const [currentTabQuestion, setCurrentTabQuestion] = React.useState<currentTabQuestionType>(0);
  return (
    <div className="ShowExam">
      <TabQuestions currentTabQuestion={currentTabQuestion} questions={props.questions} onClick={setCurrentTabQuestion} />
      <ShowQuestion currentTabQuestion={currentTabQuestion} questions={props.questions} onClick={setCurrentTabQuestion} setExam_chapters={props.setExam_chapters} />
    </div>
  );
};

export default ShowExam;
