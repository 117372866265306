import React from 'react';
import './LeftHome.css';
import Counter from './Counter/Counter';
import AttendanceRate from './AttendanceRate/AttendanceRate';
import RecentNotifications from '../../RecentNotifications/RecentNotifications';
import { errorType, RequestGET, set_error_message } from '../../../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../../public';
import LoadingAlert from '../../../Common/LoadingAlert/LoadingAlert';

interface ResponseLeftHomeType {
  count_materials: number | null;
  count_chapters: number | null;
  count_completed_chapters: number | null;
  count_examChapters: number | null;
  count_examChapters_done: number | null;
  attendanceRate: number | null;
}

const LeftHome = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);
  const [response, setResponse] = React.useState<ResponseLeftHomeType | null>(null);
  const handleError = (error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  };
  React.useEffect(() => {
    const handleDownload = (data: ResponseLeftHomeType): void => {
      setResponse(data);
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'platformLeftHome',
      },
    };

    RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, []);

  return (
    <>
      {response && (
        <div className="LeftHome">
          <Counter
            icon={
              <svg viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.73584" width="62.2642" height="60" rx="11.3208" fill="#E9FFF4" />
                <path
                  d="M20.5472 19.8113H27.3396C28.5406 19.8113 29.6924 20.2884 30.5416 21.1376C31.3908 21.9868 31.8679 23.1386 31.8679 24.3396V40.1887C31.8679 39.2879 31.5101 38.4241 30.8732 37.7872C30.2363 37.1503 29.3724 36.7924 28.4717 36.7924H20.5472V19.8113Z"
                  stroke="#299E64"
                  stroke-width="1.69811"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M43.1887 19.8113H36.3962C35.1953 19.8113 34.0435 20.2884 33.1942 21.1376C32.345 21.9868 31.8679 23.1386 31.8679 24.3396V40.1887C31.8679 39.2879 32.2257 38.4241 32.8627 37.7872C33.4996 37.1503 34.3634 36.7924 35.2642 36.7924H43.1887V19.8113Z"
                  stroke="#299E64"
                  stroke-width="1.69811"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            title={'عدد المواد'}
            data={response.count_materials ?? 0}
            color={'#299E64'}
          />
          <Counter
            icon={
              <svg viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.73584" width="62.2642" height="60" rx="11.3208" fill="#EADBF5" />
                <path
                  d="M22.8679 23.25H20.6179V39C20.6179 40.2375 21.6304 41.25 22.8679 41.25H38.6179V39H22.8679V23.25ZM40.8679 18.75H27.3679C26.1304 18.75 25.1179 19.7625 25.1179 21V34.5C25.1179 35.7375 26.1304 36.75 27.3679 36.75H40.8679C42.1054 36.75 43.1179 35.7375 43.1179 34.5V21C43.1179 19.7625 42.1054 18.75 40.8679 18.75ZM40.8679 34.5H27.3679V21H40.8679V34.5ZM33.5667 27.93C34.0279 27.1087 34.8942 26.625 35.4004 25.905C35.9404 25.14 35.6367 23.7225 34.1179 23.7225C33.1279 23.7225 32.6329 24.4763 32.4304 25.1063L30.8892 24.465C31.3167 23.205 32.4529 22.125 34.1067 22.125C35.4904 22.125 36.4467 22.755 36.9304 23.5425C37.3467 24.2175 37.5829 25.4887 36.9417 26.4338C36.2329 27.48 35.5579 27.795 35.1867 28.47C35.0404 28.74 34.9842 28.92 34.9842 29.7975H33.2742C33.2854 29.3362 33.2067 28.5825 33.5667 27.93ZM32.9367 32.1937C32.9367 31.53 33.4654 31.0238 34.1179 31.0238C34.7817 31.0238 35.2879 31.53 35.2879 32.1937C35.2879 32.8462 34.7929 33.375 34.1179 33.375C33.4654 33.375 32.9367 32.8462 32.9367 32.1937Z"
                  fill="#8913E3"
                />
              </svg>
            }
            title={'عدد الاختبارات'}
            data={response.count_examChapters ?? 0}
            color={'#8913E3'}
          />
          <Counter
            icon={
              <svg viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.73584" width="62.2642" height="60" rx="11.3208" fill="#FFF0E0" />
                <path
                  d="M43.1179 22.3275L30.2817 35.175L25.5117 30.405L27.0979 28.8187L30.2817 32.0025L41.5317 20.7525L43.1179 22.3275ZM40.6317 27.9975C40.7779 28.6387 40.8679 29.3137 40.8679 30C40.8679 34.9725 36.8404 39 31.8679 39C26.8954 39 22.8679 34.9725 22.8679 30C22.8679 25.0275 26.8954 21 31.8679 21C33.6454 21 35.2879 21.5175 36.6829 22.4062L38.3029 20.7862C36.4202 19.4582 34.1719 18.7468 31.8679 18.75C25.6579 18.75 20.6179 23.79 20.6179 30C20.6179 36.21 25.6579 41.25 31.8679 41.25C38.0779 41.25 43.1179 36.21 43.1179 30C43.1179 28.6612 42.8704 27.3787 42.4429 26.1862L40.6317 27.9975Z"
                  fill="#DD7C12"
                />
              </svg>
            }
            title={'عدد الامتحانات المجتازة'}
            data={response.count_examChapters_done ?? 0}
            color={'#DD7C12'}
          />
          <AttendanceRate rate={response.attendanceRate ?? 0} />
          <RecentNotifications />
        </div>
      )}
      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </>
  );
};

export default LeftHome;
