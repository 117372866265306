import React from 'react';
import './ReplaySvg.css';

type PropsType = {
  onclick?: () => void;
};

const ReplaySvg: React.FC<PropsType> = (props) => {
  return (
    <svg onClick={props.onclick} className="ReplaySvg" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6943 4.94531L9.88184 0.816406C9.64486 0.634115 9.40788 0.597656 9.1709 0.707031C8.93392 0.816406 8.80632 1.01693 8.78809 1.30859V3.49609C7.31152 3.49609 5.98079 3.63281 4.7959 3.90625C3.611 4.17969 2.6722 4.68099 1.97949 5.41016C1.28678 6.13932 0.931315 7.1875 0.913086 8.55469C0.931315 9.41146 1.15918 10.2135 1.59668 10.9609C2.03418 11.7266 2.56283 12.3281 3.18262 12.7656C3.40137 12.8932 3.59277 12.9023 3.75684 12.793C3.93913 12.6654 4.00293 12.4922 3.94824 12.2734C3.34668 10.3229 3.47428 8.98307 4.33105 8.25391C5.1696 7.54297 6.65527 7.1875 8.78809 7.1875V9.59375C8.80632 9.88542 8.93392 10.0859 9.1709 10.1953C9.40788 10.3047 9.64486 10.2682 9.88184 10.0859L14.6943 5.95703C14.8402 5.8112 14.9131 5.63802 14.9131 5.4375C14.9131 5.23698 14.8402 5.07292 14.6943 4.94531Z" />
    </svg>
  );
};

export default ReplaySvg;
