import React from 'react';
import './DoneChapter.css';
import { TabsType } from '../Chapter';
import { errorType, RequestPOST, set_error_message } from '../../../../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../../../public';
import LoadingAlert from '../../../../Common/LoadingAlert/LoadingAlert';

type PropsType = {
  currentTab: TabsType;
  openMaterialPK: number | undefined;
  chapterPK: number | undefined;
};

const DoneChapter: React.FC<PropsType> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<errorType>([]);

  const handleError = React.useCallback((error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  }, []);

  const handleDownload = React.useCallback(
    (data: number | null): void => {
      setLoading(false);
      if (data === null) {
        window.location.href = `/gate/platform/materials/`;
      } else {
        window.location.href = `/gate/platform/materials/${props.openMaterialPK}/${data}`;
      }
    },
    [props.openMaterialPK],
  );

  const sendComplete = React.useCallback((): void => {
    const bodyFormData = new FormData();
    bodyFormData.append('main_target', 'platformChapter');
    bodyFormData.append('completeChapter', 'true');
    bodyFormData.append('openMaterialPK', `${props.openMaterialPK}`);
    bodyFormData.append('chapterPK', `${props.chapterPK}`);
    RequestPOST(url_studentGate, bodyFormData, handleDownload, handleError);
    setLoading(true);
  }, [, handleDownload, handleError, props.openMaterialPK, props.chapterPK]);

  return (
    <div className="DoneChapter" style={{ display: props.currentTab === TabsType.DoneChapter ? 'block' : 'none' }}>
      <p className="titleDoneChapter">هل انت متاكد من انهاء الوحدة و تم مشاهدة جميع محاضرات و مصادر و حل الامتحانات للهذه الوحدة</p>
      <button className="yesDoneChapter" onClick={sendComplete}>
        نعم
      </button>

      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default DoneChapter;
