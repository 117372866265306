import React from 'react';
import './ToggleMenu.css';

type PropsType = {
  deg: number;
  onClickToggleMenu?: () => void;
};

const ToggleMenu: React.FC<PropsType> = (props) => {
  return (
    <svg onClick={props.onClickToggleMenu} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${props.deg}deg)` }}>
      <g clip-path="url(#clip0_27_6)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0654 6.56548C13.8102 6.82072 13.6669 7.16686 13.6669 7.52778C13.6669 7.8887 13.8102 8.23484 14.0654 8.49009L19.9087 14.3333L14.0654 20.1766C13.8175 20.4333 13.6803 20.7771 13.6834 21.134C13.6865 21.4909 13.8296 21.8323 14.082 22.0846C14.3344 22.337 14.6757 22.4801 15.0326 22.4832C15.3895 22.4863 15.7333 22.3491 15.99 22.1012L22.7956 15.2956C23.0507 15.0404 23.1941 14.6943 23.1941 14.3333C23.1941 13.9724 23.0507 13.6263 22.7956 13.371L15.99 6.56548C15.7348 6.31031 15.3886 6.16696 15.0277 6.16696C14.6668 6.16696 14.3207 6.31031 14.0654 6.56548ZM5.89874 6.56548C5.64357 6.82072 5.50023 7.16686 5.50023 7.52778C5.50023 7.8887 5.64357 8.23484 5.89874 8.49009L11.742 14.3333L5.89874 20.1766C5.6508 20.4333 5.51361 20.7771 5.51671 21.134C5.51982 21.4909 5.66296 21.8323 5.91532 22.0846C6.16768 22.337 6.50907 22.4801 6.86595 22.4832C7.22283 22.4863 7.56665 22.3491 7.82335 22.1012L14.6289 15.2956C14.8841 15.0404 15.0274 14.6943 15.0274 14.3333C15.0274 13.9724 14.8841 13.6263 14.6289 13.371L7.82335 6.56548C7.56811 6.31031 7.22197 6.16696 6.86105 6.16696C6.50013 6.16696 6.15399 6.31031 5.89874 6.56548Z"
          fill="#353333"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_6">
          <rect width="28" height="28" fill="white" transform="matrix(-1 0 0 1 28 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ToggleMenu;
