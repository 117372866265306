import * as React from "react";

const IsolationMode = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={334} height={189} viewBox="0 0 334 189" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_43_930)">
    <path d="M241.088 5.725C240.975 5.24588 240.648 4.8298 240.209 4.61546C239.769 4.40112 239.242 4.40112 238.803 4.61546L199.86 22.9607C199.157 23.2885 198.793 24.0702 198.956 24.8141L206.752 58.718C206.865 59.235 207.229 59.6511 207.706 59.8654C207.907 59.9537 208.133 59.9915 208.347 59.9915C208.635 59.9915 208.924 59.9158 209.188 59.7645L226.851 49.1609C227.63 48.6944 227.881 47.6857 227.416 46.904C226.952 46.1223 225.947 45.8701 225.169 46.3366L209.426 55.7929L202.434 25.3815L238.338 8.46102L244.402 34.7873L234.233 40.8898C233.455 41.3563 233.204 42.365 233.668 43.1467C234.133 43.9284 235.137 44.1806 235.915 43.7141L247.114 36.9938C247.729 36.6282 248.03 35.9095 247.867 35.216L241.088 5.73761V5.725Z" fill="white" fillOpacity={0.2}/>
    <path d="M202.158 24.0702C201.982 23.3137 201.279 22.7841 200.538 22.7967L157.289 23.5154C156.8 23.5154 156.335 23.755 156.022 24.1458C155.72 24.5367 155.607 25.041 155.72 25.5328L162.499 55.0112C162.663 55.7046 163.253 56.2216 163.956 56.2846L208.221 59.9915C208.221 59.9915 208.309 59.9915 208.359 59.9915H208.384C209.288 59.9915 210.016 59.2602 210.016 58.3524C210.016 58.1254 209.966 57.9111 209.891 57.7094L202.158 24.0828V24.0702ZM165.412 53.0947L159.348 26.7684L199.258 26.1001L206.25 56.5116L165.412 53.0947Z" fill="white" fillOpacity={0.2}/>
    <path d="M247.892 0.189918L194.726 -3C194.412 -3.01261 194.11 -2.94957 193.834 -2.79827L145.74 23.8937C145.087 24.2593 144.761 25.0284 144.949 25.7597C145.137 26.4784 145.79 26.9827 146.531 26.9827C146.531 26.9827 146.543 26.9827 146.556 26.9827L200.588 26.0875C200.827 26.0875 201.053 26.0245 201.266 25.9236L248.507 3.3168C249.185 2.98898 249.549 2.25769 249.411 1.5138C249.273 0.782512 248.645 0.227743 247.904 0.189918H247.892ZM200.174 22.7967L153.071 23.5785L195.002 0.316002L241.351 3.10246L200.174 22.8094V22.7967Z" fill="white" fillOpacity={0.2}/>
    <path d="M224.14 12.6848C223.964 12.0418 223.424 11.5753 222.771 11.487L198.894 8.48624C198.002 8.37276 197.174 9.01579 197.061 9.91099C196.948 10.8062 197.588 11.6383 198.479 11.7518L221.277 14.6139L225.621 30.3366C225.822 31.0679 226.475 31.547 227.19 31.547C227.341 31.547 227.479 31.5218 227.63 31.4839C228.496 31.2444 229.011 30.3366 228.772 29.454L224.14 12.6722V12.6848Z" fill="white" fillOpacity={0.2}/>
    <path d="M228.496 28.0671C228.057 28.0671 227.617 28.1175 227.19 28.2184C224.089 28.937 222.144 32.0639 222.859 35.1782C223.474 37.8259 225.784 39.6794 228.483 39.6794C228.923 39.6794 229.362 39.6289 229.789 39.5281C231.295 39.175 232.576 38.2672 233.392 36.9434C234.208 35.6321 234.472 34.0686 234.12 32.5556C233.505 29.9079 231.195 28.0544 228.496 28.0544V28.0671ZM230.618 35.2034C230.266 35.7708 229.714 36.1742 229.061 36.3255C228.873 36.3634 228.684 36.3886 228.496 36.3886C227.328 36.3886 226.324 35.5817 226.06 34.4343C225.747 33.0852 226.588 31.7235 227.944 31.4083C228.132 31.3705 228.32 31.3452 228.508 31.3452C229.676 31.3452 230.68 32.1522 230.944 33.2995C231.095 33.9552 230.982 34.6234 230.63 35.2034H230.618Z" fill="white" fillOpacity={0.2}/>
    <path d="M233.266 35.8212C232.84 35.0269 231.848 34.7243 231.044 35.153C230.253 35.5817 229.952 36.5777 230.379 37.3847L237.497 50.6865L227.73 52.956L228.32 37.8764C228.358 36.9686 227.655 36.1995 226.751 36.1742C225.91 36.1364 225.081 36.8425 225.056 37.7503L224.378 54.986C224.353 55.5029 224.579 55.9946 224.968 56.3224C225.27 56.5746 225.634 56.7007 226.01 56.7007C226.136 56.7007 226.261 56.6881 226.374 56.6629L240.322 53.4225C240.824 53.309 241.238 52.9686 241.439 52.5021C241.64 52.0356 241.627 51.4934 241.389 51.0521L233.254 35.859L233.266 35.8212Z" fill="white" fillOpacity={0.2}/>
    <path d="M282.441 76.3446C281.122 76.0546 279.767 75.9159 278.423 75.9159C269.46 75.9159 261.601 82.3083 259.73 91.1216C257.533 101.486 264.124 111.724 274.444 113.93C275.762 114.22 277.118 114.359 278.461 114.359C287.425 114.359 295.283 107.966 297.166 99.1531C299.363 88.789 292.773 78.551 282.453 76.3446H282.441ZM293.953 98.4723C292.396 105.773 285.88 111.081 278.448 111.081C277.331 111.081 276.214 110.967 275.122 110.728C266.572 108.887 261.099 100.401 262.919 91.815C264.476 84.5148 270.991 79.2067 278.411 79.2067C279.528 79.2067 280.645 79.3201 281.75 79.5597C290.299 81.4005 295.773 89.886 293.953 98.4723Z" fill="white" fillOpacity={0.2}/>
    <path d="M281.148 82.447C280.256 82.2579 279.34 82.157 278.436 82.157C272.385 82.157 267.074 86.4817 265.806 92.4329C264.325 99.4305 268.782 106.34 275.749 107.84C276.641 108.029 277.557 108.13 278.461 108.13C284.512 108.13 289.822 103.806 291.09 97.8545C292.584 90.8568 288.115 83.9348 281.148 82.447ZM287.876 97.1736C286.935 101.624 282.968 104.84 278.448 104.84C277.77 104.84 277.093 104.764 276.415 104.625C271.205 103.503 267.878 98.3462 268.995 93.1137C269.937 88.663 273.904 85.4478 278.423 85.4478C279.101 85.4478 279.779 85.5235 280.457 85.6622C285.667 86.7843 288.994 91.9411 287.876 97.1736Z" fill="white" fillOpacity={0.2}/>
    <path d="M330.108 98.4345L306.042 93.0129C305.615 92.912 305.176 93.0002 304.799 93.2272C304.435 93.4668 304.172 93.8324 304.084 94.2737L301.485 106.466C301.297 107.349 301.849 108.219 302.728 108.42L326.781 113.842C326.894 113.867 327.02 113.88 327.146 113.88C327.459 113.88 327.761 113.791 328.024 113.615C328.388 113.375 328.652 113.01 328.74 112.581L331.339 100.389C331.527 99.5062 330.975 98.6362 330.096 98.4471L330.108 98.4345ZM325.89 110.261L305.025 105.558L306.934 96.581L327.798 101.284L325.89 110.261Z" fill="white" fillOpacity={0.2}/>
    <path d="M304.724 99.1027L296.451 97.3249C295.56 97.1358 294.693 97.7032 294.505 98.5858C294.317 99.4683 294.882 100.351 295.76 100.54L304.034 102.318C304.147 102.343 304.26 102.356 304.372 102.356C305.126 102.356 305.804 101.826 305.967 101.057C306.155 100.174 305.59 99.2918 304.711 99.1027H304.724Z" fill="white" fillOpacity={0.2}/>
    <path d="M277.432 177.073C277.206 176.19 276.314 175.661 275.435 175.888C274.557 176.115 274.029 177.01 274.255 177.892C274.381 178.372 274.506 178.838 274.632 179.317C262.718 173.467 261.061 156.723 261.049 156.572C261.049 156.496 261.061 156.42 261.111 156.37C261.162 156.32 261.237 156.294 261.312 156.282L271.468 156.559C271.493 157.316 271.531 158.072 271.569 158.841C271.619 159.749 272.385 160.442 273.289 160.405C274.193 160.354 274.883 159.585 274.845 158.677C274.783 157.404 274.732 156.156 274.707 154.933C274.695 154.05 273.992 153.344 273.113 153.319L261.4 153.004C260.408 152.978 259.429 153.382 258.739 154.113C258.061 154.832 257.709 155.828 257.784 156.849C257.86 157.744 259.956 178.762 276.741 183.655C276.892 183.692 277.042 183.718 277.193 183.718C277.632 183.718 278.072 183.541 278.386 183.201C278.8 182.759 278.951 182.116 278.75 181.536C278.26 180.112 277.808 178.624 277.419 177.086L277.432 177.073Z" fill="white" fillOpacity={0.2}/>
    <path d="M330.573 155.033L318.672 154.706C318.672 154.706 318.646 154.706 318.621 154.706C317.78 154.706 317.065 155.361 316.989 156.206C316.889 157.429 316.763 158.665 316.613 159.938C316.5 160.833 317.14 161.653 318.044 161.766C318.935 161.88 319.751 161.237 319.864 160.329C319.952 159.56 320.04 158.791 320.115 158.034L330.472 158.312C330.535 158.312 330.61 158.349 330.661 158.413C330.698 158.463 330.723 158.539 330.711 158.602C330.686 158.791 328.1 176.064 315.182 180.893C315.345 180.439 315.508 179.973 315.671 179.494C315.96 178.636 315.508 177.703 314.654 177.401C313.801 177.111 312.872 177.565 312.57 178.422C312.056 179.935 311.503 181.385 310.926 182.709C310.687 183.264 310.775 183.919 311.152 184.386C311.466 184.777 311.943 185.004 312.432 185.004C312.533 185.004 312.646 185.004 312.746 184.978C320.115 183.541 326.129 178.599 330.121 170.706C333.159 164.692 333.95 159.093 333.95 159.056C334.088 158.059 333.787 157.038 333.146 156.282C332.494 155.513 331.552 155.059 330.56 155.033H330.573Z" fill="white" fillOpacity={0.2}/>
    <path d="M318.998 149.322L273.126 148.074C272.699 148.061 272.272 148.225 271.958 148.528C271.644 148.83 271.456 149.246 271.456 149.688C271.418 151.402 271.418 153.193 271.456 154.996C271.481 156.257 271.531 157.543 271.594 158.841C271.958 165.915 272.849 172.332 274.28 177.893C274.695 179.506 275.172 181.095 275.687 182.595C278.787 191.61 283.432 197.259 289.496 199.402C291.141 199.982 292.886 200.298 294.693 200.348H295.032C295.635 200.348 296.263 200.31 297.003 200.222C304.021 199.377 309.733 193.918 313.976 184.02C314.579 182.621 315.156 181.095 315.709 179.494C317.617 173.87 319.061 167.238 319.889 160.329C320.04 159.03 320.178 157.744 320.278 156.483C320.442 154.592 320.555 152.739 320.605 150.999C320.617 150.558 320.454 150.141 320.165 149.814C319.864 149.498 319.45 149.309 319.023 149.297L318.998 149.322ZM317.002 156.219C316.901 157.442 316.776 158.677 316.625 159.951C315.822 166.633 314.428 173.038 312.595 178.447C312.081 179.96 311.528 181.41 310.951 182.734C307.197 191.484 302.364 196.276 296.589 196.969C295.986 197.045 295.484 197.07 295.007 197.07H294.756C293.287 197.032 291.881 196.767 290.563 196.301C285.491 194.51 281.524 189.543 278.762 181.524C278.273 180.099 277.821 178.611 277.432 177.073C276.063 171.714 275.184 165.524 274.845 158.665C274.783 157.391 274.732 156.143 274.707 154.92C274.682 153.735 274.67 152.562 274.682 151.402L317.24 152.562C317.178 153.747 317.102 154.97 316.989 156.219H317.002Z" fill="white" fillOpacity={0.2}/>
    <path d="M308.34 166.041L300.594 165.826L297.305 158.047C297.054 157.454 296.476 157.063 295.848 157.051C295.221 157.026 294.618 157.391 294.329 157.971L290.626 165.561L282.88 165.347C282.24 165.335 281.625 165.7 281.348 166.293C281.072 166.885 281.173 167.579 281.599 168.071L287.249 174.513L285.667 182.482C285.541 183.1 285.78 183.73 286.27 184.108C286.759 184.487 287.425 184.55 287.989 184.285L295.196 180.729L302.201 184.676C302.452 184.815 302.728 184.89 303.004 184.89C303.331 184.89 303.657 184.789 303.933 184.6C304.448 184.247 304.711 183.629 304.624 183.012L303.481 174.967L309.469 168.84C309.921 168.373 310.059 167.68 309.821 167.075C309.582 166.469 308.992 166.066 308.352 166.041H308.34ZM300.569 173.227C300.217 173.593 300.041 174.11 300.117 174.614L300.908 180.162L296.037 177.426C295.786 177.287 295.509 177.212 295.233 177.212C294.982 177.212 294.744 177.262 294.505 177.376L289.496 179.847L290.588 174.35C290.689 173.845 290.551 173.328 290.212 172.937L286.521 168.726L291.567 168.865C292.208 168.865 292.798 168.524 293.086 167.944L295.673 162.636L297.983 168.083C298.234 168.676 298.811 169.067 299.439 169.079L304.485 169.218L300.569 173.227Z" fill="white" fillOpacity={0.2}/>
    <path d="M189.604 181.864C189.202 181.826 179.661 180.893 164.772 185.331C154.992 188.244 147.874 193.186 144.735 195.632L125.478 148.679C126.432 147.746 128.177 146.094 130.01 144.632C130.713 144.064 130.838 143.03 130.273 142.324C129.708 141.618 128.679 141.492 127.976 142.059C125.076 144.367 122.49 147.027 122.377 147.141C121.925 147.607 121.787 148.313 122.038 148.918L142.551 198.948C142.752 199.44 143.191 199.806 143.706 199.932C143.819 199.957 143.945 199.97 144.07 199.97C144.472 199.97 144.874 199.818 145.175 199.541C145.25 199.465 153.322 192.178 165.701 188.484C179.899 184.247 189.164 185.13 189.265 185.142C190.168 185.231 190.972 184.575 191.06 183.68C191.148 182.772 190.495 181.965 189.604 181.877V181.864Z" fill="white" fillOpacity={0.2}/>
    <path d="M172.731 133.599C172.593 133.234 172.329 132.931 171.99 132.729C171.714 132.578 165.148 128.972 155.118 128.972C152.218 128.972 149.293 129.275 146.418 129.88C133.813 132.528 127.21 138.63 126.934 138.895C126.444 139.361 126.281 140.08 126.532 140.698L145.953 189.782C146.141 190.261 146.556 190.627 147.045 190.766C147.183 190.803 147.322 190.816 147.472 190.816C147.836 190.816 148.2 190.69 148.489 190.463C148.552 190.413 155.017 185.344 166.04 183.201C169.492 182.532 172.794 182.192 175.869 182.192C184.406 182.192 188.499 184.814 188.524 184.84C189.114 185.243 189.892 185.205 190.445 184.764C191.01 184.323 191.21 183.566 190.972 182.898L172.731 133.561V133.599ZM175.857 178.926C172.58 178.926 169.065 179.279 165.412 179.998C156.938 181.637 151.012 184.878 148.225 186.668L130.01 140.61C132.068 139.008 137.893 135.049 147.083 133.108C149.744 132.553 152.444 132.263 155.118 132.263C162.738 132.263 168.237 134.557 169.881 135.339L186.565 180.452C184.168 179.67 180.627 178.926 175.857 178.926Z" fill="white" fillOpacity={0.2}/>
    <path d="M173.133 151.806C170.584 150.532 163.604 147.784 153.724 149.864C151.201 150.394 148.753 151.138 146.443 152.058C144.158 152.966 142.036 154.05 140.128 155.273C139.362 155.765 139.136 156.773 139.626 157.543C139.94 158.034 140.467 158.299 141.007 158.299C141.308 158.299 141.609 158.211 141.886 158.047C143.618 156.937 145.564 155.954 147.661 155.122C149.795 154.264 152.067 153.584 154.415 153.092C163.29 151.226 169.454 153.634 171.702 154.756C172.505 155.16 173.497 154.832 173.898 154.025C174.3 153.218 173.974 152.222 173.17 151.818L173.133 151.806Z" fill="white" fillOpacity={0.2}/>
    <path d="M168.789 140.975C166.24 139.702 159.26 136.953 149.38 139.033C146.845 139.563 144.396 140.307 142.087 141.215C139.802 142.135 137.668 143.207 135.772 144.43C135.006 144.922 134.78 145.93 135.27 146.699C135.584 147.191 136.111 147.456 136.651 147.456C136.952 147.456 137.253 147.368 137.529 147.204C138.722 146.447 140.68 145.325 143.304 144.279C145.438 143.434 147.698 142.753 150.058 142.249C158.934 140.383 165.098 142.791 167.345 143.913C168.149 144.316 169.14 143.989 169.542 143.182C169.944 142.375 169.618 141.379 168.814 140.975H168.789Z" fill="white" fillOpacity={0.2}/>
    <path d="M180.678 172.004C178.142 170.731 171.162 167.982 161.269 170.063C158.746 170.592 156.298 171.324 153.975 172.244C151.69 173.152 149.569 174.236 147.661 175.459C146.895 175.951 146.669 176.959 147.158 177.729C147.472 178.22 147.999 178.485 148.539 178.485C148.841 178.485 149.142 178.397 149.418 178.233C150.611 177.476 152.582 176.342 155.18 175.308C157.327 174.45 159.599 173.77 161.934 173.278C170.823 171.412 176.974 173.82 179.209 174.942C180.012 175.346 181.004 175.018 181.406 174.211C181.807 173.404 181.481 172.408 180.678 172.004Z" fill="white" fillOpacity={0.2}/>
    <path d="M176.861 161.842C174.313 160.569 167.333 157.82 157.453 159.9C154.929 160.43 152.469 161.161 150.159 162.094C147.861 163.015 145.74 164.086 143.844 165.309C143.078 165.801 142.852 166.81 143.342 167.579C143.656 168.071 144.183 168.335 144.723 168.335C145.024 168.335 145.325 168.247 145.602 168.083C146.782 167.327 148.753 166.205 151.364 165.158C153.498 164.313 155.77 163.62 158.118 163.128C166.994 161.262 173.158 163.67 175.405 164.792C176.221 165.196 177.2 164.868 177.602 164.061C178.004 163.254 177.677 162.258 176.861 161.855V161.842Z" fill="white" fillOpacity={0.2}/>
    <path d="M225.533 113.653C225.232 112.795 224.29 112.354 223.437 112.657L216.846 115.04C215.992 115.342 215.553 116.288 215.854 117.145C216.155 118.003 217.097 118.444 217.951 118.141L222.997 116.313L240.774 165.94L191.474 187.651L139.864 203.878L120.468 154.857L125.452 152.865C126.294 152.524 126.708 151.579 126.369 150.734C126.03 149.889 125.088 149.473 124.247 149.814L117.744 152.411C117.343 152.575 117.016 152.89 116.84 153.281C116.665 153.684 116.665 154.138 116.828 154.542L137.379 206.514C137.63 207.157 138.245 207.547 138.898 207.547C139.061 207.547 139.224 207.522 139.387 207.472L192.529 190.766C192.529 190.766 192.642 190.728 192.692 190.703L243.473 168.335C244.264 167.995 244.653 167.087 244.352 166.268L225.508 113.653H225.533Z" fill="white" fillOpacity={0.2}/>
    <path d="M217.637 112.052C217.373 111.295 216.607 110.828 215.816 110.967C215.779 110.967 212.439 111.535 208.962 111.724C208.058 111.774 207.367 112.543 207.418 113.451C207.468 114.359 208.234 115.052 209.138 115.002C211.448 114.876 213.644 114.611 215 114.422L231.71 163.015C227.743 163.254 219.156 164.288 209.903 168.613C195.83 175.182 189.29 182.23 189.013 182.52C188.398 183.188 188.449 184.222 189.114 184.84C189.428 185.13 189.83 185.268 190.219 185.268C190.658 185.268 191.097 185.092 191.424 184.739C191.487 184.676 197.852 177.855 211.284 171.588C222.997 166.129 233.831 166.23 233.957 166.23C234.484 166.23 234.999 165.99 235.313 165.549C235.627 165.12 235.714 164.553 235.539 164.048L217.662 112.052H217.637Z" fill="white" fillOpacity={0.2}/>
    <path d="M209.15 109.732C208.924 109.101 208.322 108.672 207.656 108.647H207.468C205.886 108.647 197.45 108.95 187.118 114.851C174.074 122.302 169.843 133.133 169.668 133.587C169.53 133.965 169.53 134.381 169.668 134.759L188.675 184.209C188.913 184.84 189.528 185.256 190.206 185.256C190.231 185.256 190.256 185.256 190.294 185.256C190.997 185.218 191.6 184.726 191.788 184.045C191.813 183.97 194.173 175.787 207.568 167.856C217.185 162.157 225.42 161.64 225.508 161.628C226.023 161.602 226.5 161.325 226.789 160.896C227.077 160.468 227.14 159.926 226.964 159.434L209.163 109.732H209.15ZM205.899 165.019C196.847 170.378 192.428 175.9 190.344 179.431L172.957 134.204C173.974 131.998 178.355 123.626 188.725 117.7C196.872 113.048 203.79 112.14 206.464 111.963L223.16 158.602C219.896 159.119 213.318 160.632 205.886 165.032L205.899 165.019Z" fill="white" fillOpacity={0.2}/>
    <path d="M208.548 129.678C207.016 129.918 204.58 130.422 201.693 131.468C199.358 132.313 197.036 133.398 194.788 134.671C186.013 139.689 182.586 146.384 181.519 149.032C181.18 149.877 181.581 150.835 182.423 151.175C182.623 151.251 182.837 151.301 183.038 151.301C183.691 151.301 184.293 150.911 184.557 150.268C185.498 147.935 188.524 142.034 196.408 137.533C198.492 136.348 200.639 135.339 202.798 134.557C205.447 133.599 207.656 133.133 209.05 132.918C209.941 132.78 210.556 131.935 210.406 131.04C210.267 130.145 209.439 129.527 208.535 129.665L208.548 129.678Z" fill="white" fillOpacity={0.2}/>
    <path d="M204.668 118.671C202.434 119.024 200.136 119.629 197.814 120.461C195.479 121.306 193.156 122.378 190.909 123.664C182.146 128.682 178.707 135.377 177.64 138.025C177.301 138.87 177.702 139.828 178.543 140.168C178.744 140.244 178.958 140.294 179.159 140.294C179.799 140.294 180.414 139.903 180.678 139.273C181.619 136.94 184.657 131.04 192.529 126.539C194.613 125.353 196.759 124.345 198.919 123.576C201.555 122.617 203.777 122.163 205.171 121.936C206.062 121.798 206.677 120.953 206.526 120.058C206.388 119.163 205.56 118.545 204.656 118.684L204.668 118.671Z" fill="white" fillOpacity={0.2}/>
    <path d="M216.193 149.826C213.958 150.179 211.648 150.784 209.338 151.617C207.003 152.461 204.681 153.533 202.434 154.819C193.658 159.837 190.231 166.532 189.164 169.18C188.825 170.025 189.227 170.983 190.068 171.324C190.269 171.399 190.482 171.45 190.683 171.45C191.336 171.45 191.939 171.059 192.202 170.416C193.144 168.083 196.169 162.182 204.053 157.681C206.137 156.496 208.284 155.5 210.443 154.718C212.565 153.949 214.674 153.394 216.695 153.079C217.586 152.94 218.202 152.096 218.051 151.201C217.913 150.305 217.072 149.7 216.18 149.839L216.193 149.826Z" fill="white" fillOpacity={0.2}/>
    <path d="M212.377 139.664C210.142 140.017 207.845 140.622 205.522 141.454C203.187 142.299 200.865 143.371 198.617 144.657C189.842 149.675 186.415 156.37 185.348 159.018C185.009 159.863 185.411 160.821 186.252 161.161C186.452 161.249 186.666 161.287 186.867 161.287C187.507 161.287 188.122 160.896 188.386 160.266C189.327 157.933 192.365 152.033 200.237 147.532C202.321 146.346 204.468 145.35 206.627 144.569C208.748 143.799 210.858 143.245 212.879 142.929C213.77 142.791 214.385 141.946 214.235 141.051C214.096 140.156 213.255 139.538 212.364 139.689L212.377 139.664Z" fill="white" fillOpacity={0.2}/>
    <path d="M65.7204 160.178C61.2637 156.849 55.9784 155.097 50.4295 155.097C45.8599 155.097 41.4032 156.294 37.4738 158.589L21.6808 146.813C22.296 144.367 21.4172 141.757 19.3332 140.193C18.241 139.374 16.9354 138.945 15.5795 138.945C13.596 138.945 11.7004 139.903 10.5203 141.505L1.24284 154.05C0.238519 155.412 -0.175764 157.076 0.0627626 158.753C0.313843 160.43 1.19263 161.905 2.54846 162.914C4.56966 164.427 7.41943 164.528 9.5285 163.254L25.3215 175.03C22.999 184.991 26.8029 195.595 35.0132 201.722C39.4824 205.051 44.7928 206.816 50.3417 206.816C58.4641 206.816 66.1974 202.908 71.0307 196.364C75.1359 190.816 76.8307 183.995 75.8264 177.161C74.822 170.327 71.219 164.301 65.6953 160.178H65.7204ZM68.4195 194.397C64.1385 200.197 57.5477 203.513 50.3542 203.513C45.5084 203.513 40.8759 201.975 36.9716 199.062C29.5396 193.514 26.2379 183.768 28.7613 174.791C28.9496 174.135 28.7111 173.429 28.1587 173.026L10.4952 159.863C10.2064 159.648 9.86746 159.535 9.51595 159.535C9.10167 159.535 8.69994 159.686 8.38609 159.989C7.35666 160.985 5.64931 161.111 4.49434 160.253C3.84153 159.762 3.41469 159.056 3.3017 158.261C3.18872 157.454 3.38958 156.66 3.86663 156.004L13.1441 143.459C14.1107 142.148 16.0692 141.858 17.3873 142.829C18.5674 143.711 18.944 145.312 18.2661 146.636C17.902 147.355 18.1029 148.225 18.7432 148.704L36.4067 161.867C36.9465 162.271 37.6872 162.296 38.2647 161.93C41.8928 159.598 46.0984 158.362 50.4295 158.362C55.2628 158.362 59.8702 159.9 63.7745 162.8C68.5952 166.394 71.7337 171.664 72.6125 177.628C73.4913 183.591 71.9974 189.543 68.4195 194.384V194.397Z" fill="white" fillOpacity={0.2}/>
    <path d="M17.9146 146.788L8.21033 160.506C7.68306 161.249 7.85881 162.271 8.5995 162.8C8.88824 163.002 9.21465 163.103 9.54105 163.103C10.0558 163.103 10.5579 162.863 10.8718 162.409L20.576 148.691C21.1033 147.948 20.9276 146.926 20.1869 146.397C19.4462 145.867 18.4293 146.044 17.902 146.788H17.9146Z" fill="white" fillOpacity={0.2}/>
    <path d="M61.0754 159.219C61.3516 158.35 60.8745 157.429 60.0208 157.152C59.1671 156.874 58.2381 157.353 57.962 158.211C56.8949 161.539 56.6563 164.212 57.2464 167.037C55.8027 167.1 54.3841 167.591 53.1789 168.499C51.9737 169.407 51.1075 170.63 50.643 172.004C48.2954 171.235 45.659 171.677 43.7759 172.483C39.9093 174.135 38.2647 178.195 39.0556 184.008C37.0595 184.083 34.9881 184.827 33.2933 186.113C30.7825 188.017 29.3388 190.829 29.2383 194.044C29.2132 194.952 29.9163 195.708 30.8201 195.746C30.8327 195.746 30.8578 195.746 30.8704 195.746C31.7491 195.746 32.4773 195.04 32.5024 194.157C32.5777 191.963 33.5569 190.034 35.2517 188.748C36.8837 187.513 38.9426 187.021 40.6374 187.45C41.1898 187.588 41.7673 187.424 42.1815 187.034C42.5833 186.63 42.759 186.05 42.6335 185.495C41.8049 181.763 41.5538 177.01 45.0439 175.522C46.8014 174.766 49.3625 174.551 50.8313 175.837C51.3209 176.266 52.0365 176.367 52.6265 176.077C53.2165 175.787 53.5806 175.169 53.5429 174.513C53.4676 173.19 54.0451 171.967 55.1248 171.16C56.2421 170.327 57.6356 170.126 58.9537 170.592C59.5438 170.807 60.2217 170.655 60.6611 170.201C61.113 169.747 61.2511 169.079 61.0377 168.487C59.9078 165.335 59.9078 162.838 61.0503 159.257L61.0754 159.219Z" fill="white" fillOpacity={0.2}/>
    <path d="M74.0437 23.402C70.3402 23.402 66.6619 23.9819 63.0966 25.1293C44.2153 31.1813 33.7578 51.5439 39.7837 70.5195C44.5668 85.5487 58.326 95.648 74.0186 95.648C77.722 95.648 81.4003 95.068 84.9657 93.9333C94.1176 90.9955 101.575 84.6661 105.981 76.0924C110.388 67.5187 111.204 57.7346 108.279 48.5557C103.495 33.5265 89.7362 23.4272 74.0437 23.4272V23.402ZM103.081 74.5668C99.0764 82.3588 92.2972 88.1208 83.9739 90.7812C80.735 91.8151 77.383 92.3446 74.0311 92.3446C59.7572 92.3446 47.2534 83.1657 42.9097 69.4982C37.4236 52.2499 46.9395 33.7534 64.1009 28.2436C67.3398 27.2097 70.6918 26.6801 74.0562 26.6801C88.3302 26.6801 100.834 35.859 105.178 49.5265C107.839 57.8733 107.086 66.7748 103.094 74.5542L103.081 74.5668Z" fill="white" fillOpacity={0.2}/>
    <path d="M66.0719 24.6628C65.055 24.6628 64.0507 24.8141 63.0966 25.1167C58.3009 26.6549 55.0745 31.7991 54.02 39.6037C53.0157 46.9796 54.0451 56.0072 56.9074 65.0096C62.3182 82.0183 72.8636 94.3746 81.9904 94.3746C83.0198 94.3746 84.0116 94.2233 84.9657 93.9207C89.7613 92.3824 92.9877 87.2382 94.0423 79.4336C95.0466 72.0577 94.0171 63.0301 91.1548 54.0277C85.744 37.0064 75.1986 24.6628 66.0719 24.6628ZM90.8033 78.9797C89.9371 85.3722 87.4514 89.6716 83.9739 90.7812C83.3462 90.9829 82.6808 91.0838 81.9904 91.0838C74.3701 91.0838 64.9295 79.4462 60.0208 64.001C57.3092 55.4651 56.3299 46.9544 57.2589 40.045C58.1252 33.6526 60.6109 29.3531 64.0883 28.2436C64.716 28.0419 65.3814 27.941 66.0719 27.941C73.6922 27.941 83.1328 39.5785 88.0414 55.0238C90.7531 63.5597 91.7323 72.0703 90.8033 78.9797Z" fill="white" fillOpacity={0.2}/>
    <path d="M65.281 26.5793C65.0048 25.7093 64.0883 25.2428 63.2221 25.5076C62.3559 25.7849 61.8914 26.7054 62.155 27.5753L82.7938 92.4581C83.0198 93.1515 83.6601 93.6054 84.3505 93.6054C84.5137 93.6054 84.6895 93.5802 84.8527 93.5298C85.7189 93.2524 86.1834 92.332 85.9198 91.462L65.281 26.5793Z" fill="white" fillOpacity={0.2}/>
    <path d="M105.956 70.2043C98.3232 68.5778 88.2046 69.473 78.2242 72.6755C68.0554 75.9411 59.2048 81.1862 53.9447 87.0743C53.3421 87.7552 53.3923 88.789 54.0702 89.3942C54.3841 89.6716 54.7732 89.8103 55.1624 89.8103C55.6144 89.8103 56.0538 89.6212 56.3802 89.2682C61.2637 83.7961 69.587 78.8915 79.2159 75.8024C88.6691 72.7638 98.1725 71.8938 105.278 73.4068C106.157 73.5959 107.036 73.0286 107.224 72.146C107.412 71.2634 106.847 70.3808 105.969 70.1917L105.956 70.2043Z" fill="white" fillOpacity={0.2}/>
    <path d="M91.4812 30.0592C86.5726 35.4304 78.3246 40.2468 68.8714 43.2854C59.2425 46.3744 49.6261 47.2192 42.4954 45.6053C41.6166 45.4036 40.7378 45.9584 40.537 46.8536C40.3361 47.7361 40.8885 48.6187 41.7798 48.8205C44.3785 49.4131 47.2659 49.7031 50.3417 49.7031C56.3802 49.7031 63.1468 48.5935 69.8757 46.4375C79.8562 43.2349 88.6189 38.0907 93.9042 32.3035C94.5193 31.6352 94.4691 30.6013 93.8037 29.9835C93.1384 29.3657 92.0964 29.4162 91.4938 30.0844L91.4812 30.0592Z" fill="white" fillOpacity={0.2}/>
    <path d="M105.843 47.5848L41.24 68.313C40.3738 68.5904 39.9093 69.5108 40.1729 70.3808C40.3989 71.0743 41.0391 71.5282 41.7296 71.5282C41.8928 71.5282 42.0686 71.5029 42.2318 71.4525L106.835 50.7243C107.701 50.4469 108.166 49.5265 107.902 48.6566C107.626 47.7866 106.709 47.3201 105.843 47.5848Z" fill="white" fillOpacity={0.2}/>
    <path d="M97.8838 106.958C95.147 106.958 92.3475 107.412 89.5479 108.307C84.8904 109.795 80.8605 112.354 78.1865 115.494C75.3242 118.847 74.3324 122.504 75.3744 125.782C75.4999 126.198 75.8012 126.539 76.1779 126.74C76.4164 126.866 76.6675 126.929 76.9311 126.929C77.0943 126.929 77.2701 126.904 77.4333 126.854L110.124 116.364C110.99 116.086 111.455 115.166 111.191 114.296C109.76 109.769 104.663 106.958 97.8963 106.958H97.8838ZM78.2493 123.134C78.2618 121.382 79.1029 119.49 80.6848 117.624C82.9696 114.939 86.4722 112.745 90.5522 111.434C92.9877 110.652 95.5362 110.236 97.8963 110.236C102.202 110.236 105.692 111.598 107.312 113.804L78.2618 123.122L78.2493 123.134Z" fill="white" fillOpacity={0.2}/>
    <path d="M84.5891 15.7487C83.7103 15.5343 82.819 16.0639 82.6055 16.9465C82.3921 17.8291 82.9194 18.7242 83.7982 18.9386C97.9842 22.4563 109.095 32.8961 113.526 46.8536C120.519 68.83 108.392 92.4329 86.5098 99.4557C79.6679 101.65 72.3489 102.04 65.3563 100.578C64.465 100.389 63.6113 100.969 63.423 101.851C63.2347 102.747 63.8121 103.617 64.6909 103.793C67.6913 104.423 70.742 104.739 73.7926 104.739C78.425 104.739 83.0575 104.02 87.5016 102.595C111.103 95.0176 124.184 69.5739 116.64 45.8701C111.856 30.8157 99.8673 19.569 84.5765 15.7739L84.5891 15.7487Z" fill="white" fillOpacity={0.2}/>
    <path d="M88.87 100.78C88.5938 99.9096 87.6773 99.4431 86.8111 99.7205C85.9449 99.9979 85.4804 100.918 85.7566 101.788L88.2423 109.605C88.4683 110.299 89.1085 110.753 89.799 110.753C89.9622 110.753 90.1379 110.728 90.3011 110.677C91.1674 110.4 91.6319 109.479 91.3557 108.609L88.87 100.792V100.78Z" fill="white" fillOpacity={0.2}/>
    </g>
    <defs>
    <clipPath>
    <rect width={334} height={317} fill="white" transform="translate(0 -3)"/>
    </clipPath>
    </defs>
    </svg>
  );
};

export default IsolationMode;
