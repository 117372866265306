import React from 'react';
import './DiscussionChapter.css';
import StudentSvg from './StudentSvg/StudentSvg';
import ReplaySvg from './ReplaySvg/ReplaySvg';
import TeacherSvg from './TeacherSvg/TeacherSvg';
import { useSelectedComment } from '../DiscussionsChapter';
import { CommentType } from '../../ChapterType';

export interface DiscussionType extends Omit<CommentType, 'reply'> {
  reply?: DiscussionType[] | null;
}

type PropsType = DiscussionType;

const DiscussionChapter: React.FC<PropsType> = (props) => {
  const { selectedComment, selecteCommentHandler } = useSelectedComment();
  return (
    <div className="DiscussionChapter">
      <div className={`comment ${selectedComment && selectedComment.pk === props.pk ? 'selected' : ''}`}>
        <div className="header">
          {props.typeUser === 'teacher' ? <TeacherSvg /> : <StudentSvg />}
          <p className="name">{props.student_regulation__admission_data__student__name}</p>
          <ReplaySvg onclick={() => selecteCommentHandler(props)} />
          <p className="replay" onClick={() => selecteCommentHandler(props)}>
            رد
          </p>
          <p className="date">{props.created_at}</p>
        </div>
        <p className="content">{props.content}</p>
      </div>
      {props.reply && props.reply.length > 0 && props.reply.map((discussion, index) => <DiscussionChapter key={index} {...discussion} />)}
    </div>
  );
};

export default DiscussionChapter;
