import React from 'react';
import './Counter.css';

type PropsType = {
  icon: React.ReactNode;
  title: string;
  data: number;
  color: React.CSSProperties['color'];
};

const Counter: React.FC<PropsType> = (props) => {
  return (
    <div className="Counter">
      {props.icon}
      <div className="data">
        <p className="title">{props.title}</p>
        <p className="data" style={{ color: props.color }}>
          {props.data}
        </p>
      </div>
    </div>
  );
};

export default Counter;
