import React from 'react';
import './HomePlatform.css';
import CenterHome from './CenterHome/CenterHome';
import LeftHome from './LeftHome/LeftHome';

const HomePlatform = () => {
  return (
    <div className="HomePlatform">
      <CenterHome />
      <LeftHome />
    </div>
  );
};

export default HomePlatform;
