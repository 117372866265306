import React from 'react';
import './PlatformPage.css';
import SearchBar from './SearchBar/SearchBar';

type PropsType = {
  children: React.ReactNode;
};

const PlatformPage: React.FC<PropsType> = (props) => {
  return (
    <div className="PlatformPage">
      <SearchBar />
      {props.children}
    </div>
  );
};

export default PlatformPage;
