import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Page from './Page/Page';
import Login from './Login/Login';
import PlatformProvider from './Platform/Platform';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login/" element={<Login />} />
        <Route path="gate/platform/*" element={<PlatformProvider typePage="HomePlatform" />} />
        <Route path="gate/platform/materials" element={<PlatformProvider typePage="materialsPage" />} />
        <Route path="gate/platform/materials/:openMaterialPK" element={<PlatformProvider typePage="ChapterPage" />} />
        <Route path="gate/platform/materials/:openMaterialPK/:chapterPK" element={<PlatformProvider typePage="ChapterPage" />} />
        <Route path="gate/platform/notifications" element={<PlatformProvider typePage="NotificationsPage" />} />
        <Route path="gate/platform/*" element={<PlatformProvider typePage="HomePlatform" />} />
        <Route path="*" element={<Page />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
