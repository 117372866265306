import React from 'react';
import './ExamsChapter.css';
import { TabsType } from '../Chapter';
import ListExams from './ListExams/ListExams';
import ShowExam from './ShowExam/ShowExam';
import { exam_chapterType } from '../ChapterType';

type PropsType = {
  currentTab: TabsType;
  exam_chapters: exam_chapterType[];
  setExam_chapters: (exam_chapters: exam_chapterType[]) => void;
};

const ExamsChapter: React.FC<PropsType> = (props) => {
  const [currentExam, setCurrentExam] = React.useState<exam_chapterType | null>(null);
  return (
    <div className="ExamsChapter" style={{ display: props.currentTab === TabsType.Exams ? 'block' : 'none' }}>
      {props.exam_chapters.length === 0 && <p className="noExams">لا يوجد امتحانات بعد</p>}
      {currentExam ? (
        <>
          <h3 className="titleExam">{currentExam.name}</h3>
          <ShowExam
            {...currentExam}
            setExam_chapters={(exam_chapters) => {
              props.setExam_chapters(exam_chapters);
              setCurrentExam(null);
            }}
          />
        </>
      ) : (
        <ListExams onClick={(exam) => setCurrentExam(exam)} exams={props.exam_chapters} />
      )}
    </div>
  );
};

export default ExamsChapter;
