import React from 'react';
import './Welcome.css';
import IsolationMode from './Isolation_Mode';
import Frame from './Frame';

type PropsType = {
  student_name: string;
};

const Welcome: React.FC<PropsType> = (props) => {
  return (
    <div className="welcome">
      <div className="text">
        <p>مرحبا بك يا {props.student_name}!</p>
        <p>يمكنك متابعة المحاضرات والنقاشات حول محتواها وحل الاسئلة والامتحانات الخاصة بها ومعرفة حضورك في كل وحدة لكل مادة </p>
      </div>
      <div className="image">
        <IsolationMode />
        <Frame />
      </div>
    </div>
  );
};

export default Welcome;
