import React from 'react';
import './AddComment.css';
import { useSelectedComment } from '../DiscussionsChapter';
import Cancel from '../../../../../Common/Cancel/Cancel';
import LoadingAlert from '../../../../../Common/LoadingAlert/LoadingAlert';
import { errorType, RequestPOST, set_error_message } from '../../../../../Request';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../../../../public';
import { CommentType } from '../../ChapterType';

type PropsType = {
  inputCommentRef: React.RefObject<HTMLTextAreaElement>;
  setComments: (comments: CommentType[]) => void;
};

const AddComment: React.FC<PropsType> = (props) => {
  const params = useParams();

  const openMaterialPK = params.openMaterialPK ? +params.openMaterialPK : undefined;
  const chapterPK = params.chapterPK ? +params.chapterPK : undefined;

  const { selectedComment, selecteCommentHandler } = useSelectedComment();

  const [content, setContent] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<errorType>([]);

  const handleError = React.useCallback((error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  }, []);

  const handleDownload = React.useCallback(
    (data: CommentType[]): void => {
      setLoading(false);
      props.setComments(data);
    },
    [props.setComments],
  );

  const sendComment = React.useCallback((): void => {
    const bodyFormData = new FormData();
    bodyFormData.append('main_target', 'platformComment');
    if (selectedComment) bodyFormData.append('selectedCommentPk', `${selectedComment.pk}`);
    bodyFormData.append('openMaterialPK', `${openMaterialPK}`);
    bodyFormData.append('chapterPK', `${chapterPK}`);
    bodyFormData.append('content', content);

    RequestPOST(url_studentGate, bodyFormData, handleDownload, handleError);
    setLoading(true);
  }, [selectedComment, openMaterialPK, chapterPK, content, handleDownload, handleError]);

  return (
    <div className="AddComment">
      <div className="HeadAddComment">
        <h3 className="titleAddComment">اضافة تعليق</h3>
        {selectedComment && (
          <>
            <h3 className="ReplayAddComment">الرد علي {selectedComment.student_regulation__admission_data__student__name}</h3>
            <Cancel Onclick={() => selecteCommentHandler(null)} />
          </>
        )}
      </div>
      <textarea ref={props.inputCommentRef} className="inputComment" value={content} onChange={(e) => setContent(e.target.value)} />
      {content && (
        <button className="btnAddComment" onClick={sendComment}>
          ارسال التعليق
        </button>
      )}
      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default AddComment;
