import React from 'react';
import './RecentNotifications.css';
import RecentNotification from './RecentNotification/RecentNotification';
import { errorType, RequestGET, set_error_message } from '../../Request';
import LoadingAlert from '../../Common/LoadingAlert/LoadingAlert';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../public';

type PropsType = {
  mainPage?: boolean;
};

export interface NotificationType {
  chapter__open_material__pk: number;
  chapter__pk: number;
  notification_type: 'comment' | 'addtional';
  content: string;
  created_at: string;
}
const RecentNotifications: React.FC<PropsType> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);
  const [response, setResponse] = React.useState<NotificationType[] | null>(null);

  const handleError = (error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  };
  React.useEffect(() => {
    const handleDownload = (data: NotificationType[]): void => {
      setResponse(data);
      if (data.length === 0) {
        setAlert(['لا يوجد تنبيهات']);
      }
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'platformNotification',
      },
    };

    RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, []);

  return (
    <div className="RecentNotifications">
      <div className="title">
        <p>أحدث التنبيهات</p>
        {!props.mainPage && <a href="/gate/platform/notifications/">عرض الكل</a>}
      </div>
      {response && response.map((notification, index) => <RecentNotification {...notification} />)}

      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default RecentNotifications;
