import React from 'react';
import './RecentLecture.css';
import Card from '../../../Material/Card/Card';
import poster from './posterMaterial.webp';
import { ResourceType } from '../CenterHomeType';
type PropsType = {
  resources: ResourceType[];
};

const RecentLecture: React.FC<PropsType> = (props) => {
  return (
    <div className="RecentLecture">
      <p className="title">احدث المحاضرات</p>
      <div className="cards">
        {props.resources.length === 0 && <p className="noRecentLecture">لا يوجد محاضرات حاليا</p>}
        {props.resources.map((resource) => (
          <Card
            img={`${resource.chapter__open_material__material_table__material__photo}`.replace('static/media/', 'https://student.rayaedu.org/media/')}
            title={resource.name}
            onclickButton={() => {
              window.location.href = `/gate/platform/materials/${resource.chapter__open_material__pk}/${resource.chapter__pk}`;
            }}
            professor={resource.chapter__open_material__material_table__profesor__name}
          />
        ))}
      </div>
    </div>
  );
};

export default RecentLecture;
