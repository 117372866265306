import React from 'react';
import './PlatformSidebar.css';
import logo_raya from '../../components/img/logo_raya.png';
import useOutsideAlerter from '../../components/useOutsideAlerter/useOutsideAlerter';
import { usePlatformSidebar } from './PlatformSidebarContext';

interface PropsType {
  typePage: 'HomePlatform' | 'materialsPage' | 'NotificationsPage' | 'ChapterPage';
}
const PlatformSidebar: React.FC<PropsType> = (props) => {
  const { platformSidebarStatus, togglePlatformSidebar } = usePlatformSidebar();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, togglePlatformSidebar, false, 'dblclick');
  return (
    <div ref={wrapperRef} className="Platform_sidebar" style={!platformSidebarStatus ? { display: 'none' } : undefined}>
      <img alt="logo_raya" src={logo_raya} />
      <div className={'line' + (props.typePage === 'HomePlatform' ? ' active' : '')}>
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_11_26)">
            <path d="M16.3333 3.5H24.5V11.6667H16.3333V3.5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.50379 1.65727H11.5038V11.6573H1.50379V1.65727Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.49996 16.3333H11.6666V24.5H3.49996V16.3333Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.3333 16.3333H24.5V24.5H16.3333V16.3333Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_11_26">
              <rect width="28" height="28" fill="white" transform="matrix(-1 0 0 1 28 0)" />
            </clipPath>
          </defs>
        </svg>
        <a href="/gate/platform/">نظرة سريعة</a>
      </div>
      <div className={'line' + (['materialsPage', 'ChapterPage'].includes(props.typePage) ? ' active' : '')}>
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_11_33)">
            <path d="M14 2.33334L25.6666 8.16667L14 14L2.3333 8.16667L14 2.33334Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.6666 19.8333L14 25.6667L2.3333 19.8333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.6666 14L14 19.8333L2.3333 14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_11_33">
              <rect width="28" height="28" fill="white" transform="matrix(-1 0 0 1 28 0)" />
            </clipPath>
          </defs>
        </svg>
        <a href="/gate/platform/materials">المواد الدراسية</a>
      </div>
      <div className={'line' + (props.typePage === 'NotificationsPage' ? ' active' : '')}>
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 9.33333C7 7.47681 7.7375 5.69633 9.05025 4.38358C10.363 3.07083 12.1435 2.33333 14 2.33333C15.8565 2.33333 17.637 3.07083 18.9497 4.38358C20.2625 5.69633 21 7.47681 21 9.33333C21 17.5 24.5 19.8333 24.5 19.8333H3.5C3.5 19.8333 7 17.5 7 9.33333Z"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9816 24.5C12.1868 24.8536 12.4812 25.1471 12.8354 25.3511C13.1896 25.5551 13.5912 25.6625 14 25.6625C14.4088 25.6625 14.8104 25.5551 15.1646 25.3511C15.5188 25.1471 15.8132 24.8536 16.0183 24.5"
            stroke-width="2.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle cx="19.8334" cy="5.24999" r="4.66667" fill="#FF6A55" />
        </svg>
        <a href="/gate/platform/notifications">التنبيهات</a>
      </div>
      <div className="line">
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.5 3.5H5.83333C5.2145 3.5 4.621 3.74583 4.18342 4.18342C3.74583 4.621 3.5 5.21449 3.5 5.83333V22.1667C3.5 22.7855 3.74583 23.379 4.18342 23.8166C4.621 24.2542 5.2145 24.5 5.83333 24.5H10.5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M16.3334 19.8334L10.5 14L16.3334 8.16669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.5 14H24.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <a href="/">تسجيل الخروج</a>
      </div>
    </div>
  );
};
export default PlatformSidebar;
