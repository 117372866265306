import React from 'react';
import './ListExams.css';
import { exam_chapterType } from '../../ChapterType';

type PropsType = {
  exams: exam_chapterType[];
  onClick: (exam: exam_chapterType) => void;
};

const ListExams: React.FC<PropsType> = (props) => {
  return (
    <div className="ListExams">
      {props.exams.map((exam, index) => (
        <p
          onClick={() => {
            if (!exam.found_answers) props.onClick(exam);
          }}
          key={exam.pk}
          style={exam.found_answers ? { opacity: '0.5' } : {}}
          className="examCard"
        >
          {exam.name}
        </p>
      ))}
    </div>
  );
};

export default ListExams;
