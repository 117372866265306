import React from 'react';
import './DiscussionsChapter.css';
import { TabsType } from '../Chapter';
import DiscussionChapter, { DiscussionType } from './DiscussionChapter/DiscussionChapter';
import AddComment from './AddComment/AddComment';
import { CommentType } from '../ChapterType';
type PropsType = {
  currentTab: TabsType;
  comments: CommentType[];
  setComments: (comments: CommentType[]) => void;
};

interface CommentContextType {
  selectedComment: DiscussionType | null;
  selecteCommentHandler: (commentPK: DiscussionType | null) => void;
}
export const CommentContext = React.createContext<CommentContextType | undefined>(undefined);
/////////////////////////////////////////////////////////////////////
export const useSelectedComment = (): CommentContextType => {
  const context = React.useContext(CommentContext);
  if (!context) {
    throw new Error('useSelectedComment must be used within a CommentContext.Provider');
  }
  return context;
};

const DiscussionsChapter: React.FC<PropsType> = (props) => {
  const [selectedComment, setSelectedComment] = React.useState<DiscussionType | null>(null);
  const inputCommentRef = React.useRef<HTMLTextAreaElement>(null);

  const handleSelectComment = React.useCallback((comment: DiscussionType | null) => {
    setSelectedComment(comment);
    if (comment) inputCommentRef.current?.focus();
  }, []);
  const discussions: DiscussionType[] = React.useMemo(() => {
    const getReplays = (commentPk: number): DiscussionType[] => {
      return props.comments
        .filter((comment) => comment.reply === commentPk)
        .map((comment) => {
          return { ...comment, reply: getReplays(comment.pk) };
        });
    };
    return props.comments
      .filter((comment) => comment.reply === null)
      .map((comment) => {
        return { ...comment, reply: getReplays(comment.pk) };
      });
  }, [props.comments]);
  return (
    <CommentContext.Provider
      value={{
        selectedComment: selectedComment,
        selecteCommentHandler: handleSelectComment,
      }}
    >
      <div className="DiscussionsChapter" style={{ display: props.currentTab === TabsType.Discussions ? 'block' : 'none' }}>
        <div className="discussions">
          {discussions.map((discussion, index) => (
            <DiscussionChapter key={index} {...discussion} />
          ))}
        </div>
        <AddComment inputCommentRef={inputCommentRef} setComments={props.setComments} />
      </div>
    </CommentContext.Provider>
  );
};

export default DiscussionsChapter;
