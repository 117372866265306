import React from 'react';
import './LecturesChapter.css';
import { TabsType } from '../Chapter';
import { resourceType } from '../ChapterType';

type PropsType = {
  currentTab: TabsType;
  resources: resourceType[];
};

const LecturesChapter: React.FC<PropsType> = (props) => {
  return (
    <div className="LecturesChapter" style={{ display: props.currentTab === TabsType.Lectures ? 'block' : 'none' }}>
      {props.resources.length === 0 && <p className="noResources">لا يوجد مصادر بعد</p>}
      {props.resources.map((resource, index) => (
        <div className="cardLecture">
          <div className="title">
            <p>{resource.name}</p>
            <a href={resource.url} target="_blank" rel="noreferrer">
              عرض بشاشة واسعة
            </a>
          </div>
          <iframe src={resource.url} title="Example Iframe" allow="fullscreen" sandbox="allow-scripts allow-same-origin allow-popups allow-presentation allow-downloads"></iframe>
        </div>
      ))}
    </div>
  );
};
export default LecturesChapter;
