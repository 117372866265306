import React from 'react';
import './Cancel.css';

type PropsType = {
  Onclick: () => void;
};

const Cancel: React.FC<PropsType> = (props) => {
  return (
    <svg onClick={props.Onclick} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.26318" y="0.973633" width="21.7368" height="21.7368" rx="10.8684" fill="#E43838" stroke="#E43838" />
      <path d="M17.5103 6.8418L7.51025 16.8418" stroke="white" stroke-width="2" stroke-linecap="round" />
      <path d="M7.51025 6.8418L17.5103 16.8418" stroke="white" stroke-width="2" stroke-linecap="round" />
    </svg>
  );
};

export default Cancel;
