import React from 'react';
import './TabQuestions.css';
import { currentTabQuestionType, questionType } from '../../../ChapterType';
// import Correct from '../../../../../../Common/Correct/Correct';

type PropsType = {
  currentTabQuestion: currentTabQuestionType;
  questions: questionType[];
  onClick: (currentTabQuestion: currentTabQuestionType) => void;
};

const TabQuestions: React.FC<PropsType> = (props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // Scroll the active tab into view whenever the currentTabQuestion changes
    const activeTab = containerRef.current?.querySelector('.questionTabTitle.active');
    if (activeTab) {
      activeTab.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [props.currentTabQuestion]);
  return (
    <div className="TabQuestions" ref={containerRef}>
      {props.questions.map((question, index) => (
        <button className={'questionTabTitle ' + (props.currentTabQuestion === index ? 'active' : '')} key={question.pk} onClick={() => props.onClick(index)}>
          {index + 1}
        </button>
      ))}
      <button className={'questionTabTitle ' + (props.currentTabQuestion === 'done' ? 'active' : '')} onClick={() => props.onClick('done')}>
        تسليم
      </button>
    </div>
  );
};

export default TabQuestions;
