import React from 'react';
import './UpcomingOnlineLecture.css';
import Card from '../../../Material/Card/Card';
import poster from './posterMaterial.webp';
import { OnlinelectureType } from '../CenterHomeType';

type PropsType = {
  lectures: OnlinelectureType[];
};

const UpcomingOnlineLecture: React.FC<PropsType> = (props) => {
  return (
    <div className="UpcomingOnlineLecture">
      <p className="title">المحاضرات الاونلاين المقبلة</p>
      <div className="cards">
        {props.lectures.length === 0 && <p className="noLecture">لا يوجد محاضرات حاليا</p>}
        {props.lectures.map((lecture) => (
          <Card
            key={lecture.url}
            img={`${lecture.open_material__material_table__material__photo}`.replace('static/media/', 'https://student.rayaedu.org/media/')}
            title={lecture.open_material__material_table__material__name}
            onclickButton={() => {
              window.open(lecture.url, '_blank');
            }}
            professor={lecture.open_material__material_table__profesor__name}
            time={'يبدأ ' + lecture.start_time}
          />
        ))}
      </div>
    </div>
  );
};

export default UpcomingOnlineLecture;
