import React from 'react';
import './Progress.css';
import ToggleMenu from '../../../../Common/ToggleMenu/ToggleMenu';
import { chaptersType } from '../ChapterType';

type PropsType = {
  chapters: chaptersType;
  openMaterialPK: number | undefined;
  chapterPK: number | undefined;
};

const Progress: React.FC<PropsType> = (props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={'Progress' + (!open ? ' close' : '')}>
      <div className="header" onClick={() => setOpen(!open)}>
        <ToggleMenu deg={open ? 180 : 0} />
        <p>معدل التقدم في المادة</p>
        <p>
          {props.chapters.reduce((acc, chapter) => acc + (chapter.complete ? 1 : 0), 0)}/{props.chapters.length}
        </p>
      </div>

      {open && (
        <div className="chaptersName">
          {props.chapters.map((chapter, index) => (
            <a
              href={`/gate/platform/materials/${props.openMaterialPK}/${chapter.pk}`}
              className={chapter.complete ? 'complete' : chapter.pk === props.chapterPK ? 'active' : ''}
              key={index}
            >
              {chapter.name}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Progress;
