import { createContext, useContext } from 'react';

// Define the type for the context
interface PlatformSidebarContextType {
  platformSidebarStatus: boolean;
  togglePlatformSidebar: () => void;
}

export const PlatformSidebarContext = createContext<
  PlatformSidebarContextType | undefined
>(undefined);
export const usePlatformSidebar = (): PlatformSidebarContextType => {
  const context = useContext(PlatformSidebarContext);
  if (!context) {
    throw new Error(
      'usePlatformSidebar must be used within a PlatformSidebarProvider',
    );
  }
  return context;
};
