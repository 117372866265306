import React from 'react';
import './TeacherSvg.css';

type PropsType = {};

const TeacherSvg: React.FC<PropsType> = (props) => {
  return (
    <svg className="StudentSvg" viewBox="0 0 64 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.5" width="64" height="59" rx="10" transform="matrix(-1 0 0 1 64.002 0)" fill="#E46E38" />
      <g clip-path="url(#clip0_89_39)">
        <path
          d="M40.0793 21.8943C40.0793 23.988 39.2476 25.9959 37.7671 27.4764C36.2866 28.9569 34.2787 29.7886 32.185 29.7886C30.0913 29.7886 28.0834 28.9569 26.6029 27.4764C25.1224 25.9959 24.2907 23.988 24.2907 21.8943C24.2907 19.8006 25.1224 17.7926 26.6029 16.3122C28.0834 14.8317 30.0913 14 32.185 14C34.2787 14 36.2866 14.8317 37.7671 16.3122C39.2476 17.7926 40.0793 19.8006 40.0793 21.8943ZM34.2511 34.2414L33.1039 36.1533L35.1577 43.7948L37.378 34.7348C37.5013 34.2353 37.9824 33.9084 38.4819 34.0379C42.7991 35.1172 46 39.0274 46 43.6837C46 44.7322 45.1489 45.5771 44.1066 45.5771H20.2634C19.2149 45.5771 18.37 44.726 18.37 43.6837C18.37 39.0274 21.5709 35.1172 25.8881 34.0379C26.3876 33.9146 26.8687 34.2414 26.992 34.7348L29.2123 43.7948L31.2661 36.1533L30.1189 34.2414C29.7242 33.5815 30.1991 32.7489 30.9639 32.7489H33.4C34.1647 32.7489 34.6396 33.5877 34.2449 34.2414H34.2511Z"
          fill="#282828"
        />
      </g>
      <defs>
        <clipPath id="clip0_89_39">
          <rect width="27.63" height="31.5771" fill="white" transform="matrix(-1 0 0 1 46 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeacherSvg;
