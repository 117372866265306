import React from 'react';
import '../../Common/Table.css';
import './ResultOnline.css';
import { AxiosError } from 'axios';
import { errorType, RequestGET, set_error_message } from '../../Request';
import { url_studentGate } from '../../public';
import { Tabs } from '../PageType';
import TitleTab from '../../components/TitleTab/TitleTab';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../components/Loading/Loading';

type resultsDataType = {
  question__exam__open_material__material_skill__material__name: string;
  question__exam__open_material__material_table__academic_term__academic_year__name: string;
  term_display: string;
  total_degree: number | string;
  question__exam?: string;
}[];
const ResultOnline: React.FC = () => {
  const [resultsData, setResult] = React.useState<resultsDataType>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);

  React.useEffect(() => {
    const handleError = (error: AxiosError): void => {
      setAlert(set_error_message(error));
      setLoading(false);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleDownload = (data: resultsDataType): void => {
      if (data.length > 0) setResult(data);
      else
        setAlert(['لا يوجد لديك نتيجةامتحانات الاونلاين مسجلة في ترمك الحالي']);
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'resultsOnline',
      },
    };
    RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, []);

  const closeAlert = React.useCallback(() => setAlert([]), []);
  return (
    <div>
      <div className="titles">
        <TitleTab name={Tabs.resultOnline.name} />
      </div>

      {Object.keys(resultsData).length > 0 && (
        <table className="table">
          <thead className="thead">
            <tr className="tr">
              <th className="th">اسم المادة </th>
              <th className="th">العام الدارسي</th>
              <th className="th">الترم الدراسي</th>
              <th className="th">الدرجة</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {resultsData.map((material_degree) => (
              <tr
                key={
                  material_degree.question__exam__open_material__material_skill__material__name
                }
                className="tr"
              >
                <td className="td">
                  {
                    material_degree.question__exam__open_material__material_skill__material__name
                  }
                </td>
                <td className="td">
                  {
                    material_degree.question__exam__open_material__material_table__academic_term__academic_year__name
                  }
                </td>
                <td className="td">{material_degree.term_display}</td>
                <td className="td">{material_degree.total_degree}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {alert.length > 0 && (
        <Alert
          title={'الملاحظات'}
          closeAlert={closeAlert}
          notes={alert}
          btns={
            <BtnPrimary
              verbose_name="الغاء"
              OnClickBtn={() => {
                setAlert([]);
              }}
            />
          }
        />
      )}

      {loading && <Loading />}
    </div>
  );
};

export default ResultOnline;
