import React from 'react';
import './Material.css';
import Card from './Card/Card';
import { errorType, RequestGET, set_error_message } from '../../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../public';
import LoadingAlert from '../../Common/LoadingAlert/LoadingAlert';
type PropsType = {};
interface MaterialType {
  open_material__pk: number;
  open_material__material_table__profesor__name: string;
  open_material__material_table__material__name: string;
  open_material__material_table__material__photo: string | null;
  attendanceRate: number;
}
const Material: React.FC<PropsType> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);
  const [response, setResponse] = React.useState<MaterialType[] | null>(null);
  const [selectedOpen_material_pk, setSelectedOpen_material_pk] = React.useState<number | null>(null);
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleError = (error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
    setSelectedOpen_material_pk(null);
  };
  React.useEffect(() => {
    const handleDownload = (data: MaterialType[]): void => {
      setResponse(data);
      if (data.length === 0) {
        setAlert(['لم يتم تاكيد تسجيلك بعد']);
      }
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'platformMaterials',
      },
    };

    RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
    setResponse(null);
  }, []);
  /////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    const handleDownload = (data: number | null): void => {
      if (data === null) {
        window.location.href = `/gate/platform/materials/${selectedOpen_material_pk}`;
      } else {
        window.location.href = `/gate/platform/materials/${selectedOpen_material_pk}/${data}`;
      }

      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'platformMaterials',
        selectedOpen_material_pk: selectedOpen_material_pk,
      },
    };
    if (selectedOpen_material_pk !== null) {
      RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
      setLoading(true);
    }
  }, [selectedOpen_material_pk]);
  ////////////////////////////////////////////////////////////////////////////
  return (
    <div className="Material">
      <div className="line">
        <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_58_1269)">
            <path
              d="M14.0001 2.33334L25.6667 8.16667L14.0001 14L2.33342 8.16667L14.0001 2.33334Z"
              stroke="#299E64"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M25.6667 19.8333L14.0001 25.6667L2.33342 19.8333" stroke="#299E64" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.6667 14L14.0001 19.8333L2.33342 14" stroke="#299E64" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_58_1269">
              <rect width="28" height="28" fill="white" transform="matrix(-1 0 0 1 28 0)" />
            </clipPath>
          </defs>
        </svg>

        <p>المواد الدراسية</p>
      </div>

      <div className="cards">
        {response &&
          response.map((material, index) => (
            <Card
              key={index}
              attendanceRate={material.attendanceRate ?? 0}
              img={`${material.open_material__material_table__material__photo}`.replace('static/media/', 'https://student.rayaedu.org/media/')}
              title={material.open_material__material_table__material__name}
              professor={material.open_material__material_table__profesor__name}
              onclickButton={() => setSelectedOpen_material_pk(material.open_material__pk)}
            />
          ))}
      </div>
      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default Material;
