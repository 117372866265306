import React from 'react';
import { errorType } from '../../Request';
import Loading from '../../components/Loading/Loading';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';

type PropsType = {
  loading: boolean;
  alert: errorType;
  setAlert: React.Dispatch<React.SetStateAction<errorType>>;
};

const LoadingAlert: React.FC<PropsType> = (props) => {
  const closeAlert = React.useCallback(() => {
    props.setAlert([]);
  }, [props.setAlert]);
  return (
    <>
      {props.loading && <Loading />}
      {props.alert.length > 0 && <Alert title={'ملاحظات'} btns={<BtnPrimary verbose_name={'الغاء'} OnClickBtn={closeAlert} />} notes={props.alert} closeAlert={closeAlert} />}
    </>
  );
};

export default LoadingAlert;
