import React from 'react';
import './Card.css';

type PropsType = {
  img: string;
  title: string;
  discription?: string;
  professor: string;
  onclickButton: () => void;
  time?: string;
  attendanceRate?: number;
};

const Card: React.FC<PropsType> = (props) => {
  return (
    <div className="Card">
      <div className="imgContainer"><img alt="img" src={props.img} /></div>
      <p className="title">{props.title}</p>
      {/* {props.discription && <p className="discription">{props.discription}</p>} */}
      <div className="professor_time">
        <p className="professor">{props.professor}</p>
        {props.time && <p className="time">{props.time}</p>}
      </div>
      {props.attendanceRate !== undefined && (
        <div className="attendanceRate">
          <div className="rate" style={{ background: `linear-gradient(to left,#4caf50 0% ${props.attendanceRate}%,#e0e0e0 ${props.attendanceRate}% 100%)` }}></div>
          <p>{props.attendanceRate}%</p>
        </div>
      )}
      <button onClick={props.onclickButton}>الدخول</button>
    </div>
  );
};

export default Card;
