import React from 'react';
import './Chapter.css';
import Progress from './Progress/Progress';
import LecturesChapter from './LecturesChapter/LecturesChapter';
import DiscussionsChapter from './DiscussionsChapter/DiscussionsChapter';
import ExamsChapter from './ExamsChapter/ExamsChapter';
import DoneChapter from './DoneChapter/DoneChapter';
import ToggleMenu from '../../../Common/ToggleMenu/ToggleMenu';
import { useParams } from 'react-router-dom';
import { errorType, RequestGET, set_error_message } from '../../../Request';
import { CommentType, exam_chapterType, responseType } from './ChapterType';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../../public';
import LoadingAlert from '../../../Common/LoadingAlert/LoadingAlert';

export enum TabsType {
  Lectures = 'المحاضرات و المصادر',
  Discussions = 'النقاشات',
  Exams = 'الامتحانات',
  DoneChapter = 'انهاء الوحدة',
}

type PropsType = {};

const Chapter: React.FC<PropsType> = (props) => {
  const params = useParams();
  const openMaterialPK = params.openMaterialPK ? +params.openMaterialPK : undefined;
  const chapterPK = params.chapterPK ? +params.chapterPK : undefined;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);
  const [response, setResponse] = React.useState<responseType | null>(null);

  const [currentTab, setCurrentTab] = React.useState<TabsType>(Object.values(TabsType)[0]);
  const [stickyTabs, setstickyTabs] = React.useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleError = (error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  };
  React.useEffect(() => {
    const handleDownload = (data: responseType): void => {
      setResponse(data);
      if (data.chapters.length === 0) {
        setAlert(['لم يتم اضافة وحدات بهذه المادة بعد']);
      }
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'platformChapter',
        openMaterialPK: openMaterialPK,
        chapterPK: chapterPK,
      },
    };

    RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, [openMaterialPK, chapterPK]);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const is_complete = React.useMemo(() => {
    return response?.chapters.find((chapter) => chapter.pk === chapterPK)?.complete;
  }, [response, chapterPK]);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const setResponseChpterData = React.useCallback(<K extends keyof responseType['chpterData']>(key: K, data: responseType['chpterData'][K]): void => {
    setResponse((prevResponse) => {
      if (prevResponse === null) return null;
      else
        return {
          ...prevResponse,
          chpterData: {
            ...(prevResponse?.chpterData ?? {}),
            [key]: data,
          },
          chapters: prevResponse?.chapters ?? [], // Ensure chapters is never undefined
        };
    });
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const setComments = React.useCallback((comments: CommentType[]): void => {
    setResponseChpterData('comments', comments);
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const setExam_chapters = React.useCallback((exam_chapters: exam_chapterType[]): void => {
    setResponseChpterData('exam_chapters', exam_chapters);
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="Chapter">
      {response && 'name' in response.chpterData && (
        <>
          <div className="Data">
            <p className="title">{response.chpterData.name}</p>
            <div className={'tabs' + (stickyTabs ? ' sticky' : ' relative')}>
              {Object.entries(TabsType)
                .filter(([key, value]) => value !== TabsType.DoneChapter || (!is_complete && value === TabsType.DoneChapter))
                .map(([key, value]) => (
                  <button className={currentTab === value ? 'active' : ''} onClick={() => setCurrentTab(value)} key={key}>
                    {value}
                  </button>
                ))}
              <ToggleMenu deg={stickyTabs ? 90 : -90} onClickToggleMenu={() => setstickyTabs(!stickyTabs)} />
            </div>
            <LecturesChapter currentTab={currentTab} resources={response.chpterData.resources} />
            <DiscussionsChapter currentTab={currentTab} comments={response.chpterData.comments} setComments={setComments} />
            <ExamsChapter currentTab={currentTab} exam_chapters={response.chpterData.exam_chapters} setExam_chapters={setExam_chapters} />
            {!is_complete && <DoneChapter currentTab={currentTab} openMaterialPK={openMaterialPK} chapterPK={chapterPK} />}
          </div>
          <Progress chapters={response.chapters} openMaterialPK={openMaterialPK} chapterPK={chapterPK} />
        </>
      )}

      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default Chapter;
