import React from 'react';
import './AttendanceRate.css';

type PropsType = {
  rate: number;
};

const AttendanceRate: React.FC<PropsType> = (props) => {
  const fixedRate = React.useMemo(() => {
    return props.rate.toFixed(1);
  }, [props.rate]);
  return (
    <div className="AttendanceRate">
      <p>نسبة الحضور في المواد</p>
      <div className="progress-circle" style={{ background: `conic-gradient(#4caf50 0% ${fixedRate}%,#e0e0e0 ${fixedRate}% 100%)` }}>
        <span>{fixedRate}%</span>
      </div>
    </div>
  );
};

export default AttendanceRate;
