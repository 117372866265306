import React from 'react';
import './SearchBar.css';
import { usePlatformSidebar } from '../../PlatformSidebar/PlatformSidebarContext';
import ToggleMenu from '../../../Common/ToggleMenu/ToggleMenu';
import { url_studentGate } from '../../../public';
import { RequestGET } from '../../../Request';

const SearchBar = () => {
  const { platformSidebarStatus, togglePlatformSidebar } = usePlatformSidebar();
  const [studentImage, setRstudentImage] = React.useState<string>('');
  React.useEffect(() => {
    const requestOptions = {
      params: {
        main_target: 'platformStudentImage',
      },
    };

    RequestGET(
      url_studentGate,
      requestOptions,
      (image: string) => {
        setRstudentImage(image);
      },
      () => {},
    );
  }, []);
  return (
    <div className="SearchBar">
      <ToggleMenu deg={platformSidebarStatus ? 0 : 180} onClickToggleMenu={togglePlatformSidebar} />
      <div className="search">
        <input type="text" placeholder="البحث في المحاضرات" />
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13 19C8.58172 19 5 15.4183 5 11C5 6.58172 8.58172 3 13 3C17.4183 3 21 6.58172 21 11C21 15.4183 17.4183 19 13 19Z"
            stroke="#299E64"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M3.00001 21L7.35001 16.65" stroke="#299E64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <svg
        onClick={() => {
          window.location.href = '/gate/platform/notifications';
        }}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 9.33333C7 7.47681 7.7375 5.69633 9.05025 4.38358C10.363 3.07083 12.1435 2.33333 14 2.33333C15.8565 2.33333 17.637 3.07083 18.9497 4.38358C20.2625 5.69633 21 7.47681 21 9.33333C21 17.5 24.5 19.8333 24.5 19.8333H3.5C3.5 19.8333 7 17.5 7 9.33333Z"
          stroke-width="2.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9816 24.5C12.1868 24.8536 12.4812 25.1471 12.8354 25.3511C13.1896 25.5551 13.5912 25.6625 14 25.6625C14.4088 25.6625 14.8104 25.5551 15.1646 25.3511C15.5188 25.1471 15.8132 24.8536 16.0183 24.5"
          stroke-width="2.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="19.8334" cy="5.24999" r="4.66667" fill="#FF6A55" />
      </svg>
      <img alt="صورة الطالب" src={`https://student.rayaedu.org${studentImage}`} />
    </div>
  );
};

export default SearchBar;
