import React from 'react';
import './CenterHome.css';
import Welcome from './Welcome/Welcome';
import RecentLecture from './RecentLecture/RecentLecture';
import UpcomingOnlineLecture from './UpcomingOnlineLecture/UpcomingOnlineLecture';
import { errorType, RequestGET, set_error_message } from '../../../Request';
import LoadingAlert from '../../../Common/LoadingAlert/LoadingAlert';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../../public';
import { ResponseCenterHomeType } from './CenterHomeType';

const CenterHome = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);
  const [response, setResponse] = React.useState<ResponseCenterHomeType | null>(null);
  const handleError = (error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  };
  React.useEffect(() => {
    const handleDownload = (data: ResponseCenterHomeType): void => {
      setResponse(data);
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'platformCenterHome',
      },
    };

    RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, []);

  return (
    <>
      {response && (
        <div className="CenterHome">
          <Welcome student_name={response.student_name} />
          <UpcomingOnlineLecture lectures={response.onlinelectures} />
          <RecentLecture resources={response.resources} />
        </div>
      )}
      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </>
  );
};

export default CenterHome;
