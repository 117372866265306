import React from 'react';
import './RecentNotification.css';
import { NotificationType } from '../RecentNotifications';

type PropsType = NotificationType;

const RecentNotification: React.FC<PropsType> = (props) => {
  return (
    <div className="RecentNotification" onClick={() => (window.location.href = `/gate/platform/materials/${props.chapter__open_material__pk}/${props.chapter__pk}/`)}>
      {props.notification_type === 'comment' ? (
        <svg viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.437683" width="50" height="50" rx="10" fill="#E9FFF4" />
          <path
            d="M35.5 28.9377C35.5 29.5565 35.2542 30.15 34.8166 30.5876C34.379 31.0252 33.7855 31.271 33.1667 31.271H19.1667L14.5 35.9377V17.271C14.5 16.6522 14.7458 16.0587 15.1834 15.6211C15.621 15.1835 16.2145 14.9377 16.8333 14.9377H33.1667C33.7855 14.9377 34.379 15.1835 34.8166 15.6211C35.2542 16.0587 35.5 16.6522 35.5 17.271V28.9377Z"
            stroke="#299E64"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.437683" width="50" height="50" rx="10" fill="#FFF1E9" />
          <path
            d="M33.409 22.7907C33.8893 23.0461 34.291 23.4274 34.5712 23.8937C34.8514 24.36 34.9994 24.8937 34.9994 25.4377C34.9994 25.9817 34.8514 26.5154 34.5712 26.9817C34.291 27.448 33.8893 27.8293 33.409 28.0847L20.597 35.0517C18.534 36.1747 16 34.7147 16 32.4057V18.4707C16 16.1607 18.534 14.7017 20.597 15.8227L33.409 22.7907Z"
            stroke="#F8863F"
            stroke-width="1.5"
          />
        </svg>
      )}
      <div className="data">
        <p className="notes" title={props.content}>
          {props.content}{' '}
        </p>
        <p className="time">{props.created_at}</p>
      </div>
    </div>
  );
};

export default RecentNotification;
