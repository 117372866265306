import React from 'react';
import './Platform.css';
import PlatformSidebar from './PlatformSidebar/PlatformSidebar';
import PlatformPage from './PlatformPage/PlatformPage';
import { PlatformSidebarContext } from './PlatformSidebar/PlatformSidebarContext';
import HomePlatform from './HomePlatform/HomePlatform';
import Material from './Material/Material';
import Chapter from './Material/Chapter/Chapter';
import RecentNotifications from './RecentNotifications/RecentNotifications';

interface PropsType {
  typePage: 'HomePlatform' | 'materialsPage' | 'NotificationsPage' | 'ChapterPage';
}
const Platform: React.FC<PropsType> = (props) => {
  const [platformSidebarStatus, setPlatformSidebarStatus] = React.useState<boolean>(false);

  const togglePlatformSidebar = React.useCallback(() => {
    setPlatformSidebarStatus((prevStatus) => !prevStatus);
  }, []);

  return (
    <PlatformSidebarContext.Provider value={{ platformSidebarStatus, togglePlatformSidebar }}>
      <div className="Platform" style={!platformSidebarStatus ? { gridTemplateColumns: 'minmax(10%, auto)' } : undefined}>
        {platformSidebarStatus && <PlatformSidebar {...props} />}
        <PlatformPage>
          {props.typePage === 'HomePlatform' && <HomePlatform />}
          {props.typePage === 'materialsPage' && <Material />}
          {props.typePage === 'ChapterPage' && <Chapter />}
          {props.typePage === 'NotificationsPage' && <RecentNotifications mainPage />}
        </PlatformPage>
      </div>
    </PlatformSidebarContext.Provider>
  );
};

export default Platform;
